<template>
    <cloud-header title="Coming soon" subtitle="Εξερευνήστε διατροφικά πλάνα κάθε είδους. Χρησιμοποιήστε τις ετικέτες και την αναζήτηση
για ακόμα πιο εξατομικευμένα αποτελέσματα"></cloud-header>
</template>


<script>
import CloudHeader from "@components/cloud/CloudHeader";
import CloudListing from "@components/cloud/CloudListing";

export default{

  components: {CloudHeader, CloudListing},
  data(){
    return{
      query:{
        tagIds: [],
        search: null,
        perPage: 10,
        totalRows: 0,
        order: 'latest'
      },
      page: 1,
      listings: [],
      orderings: [
        {value: 'latest', text: 'Πιο πρόσφατα'},
        {value:'oldest', text: 'Πιο παλιά'}, {value:'downloads', text: 'Περισσότερα downloads'}, {value:'likes',text: 'Περισσότερα likes'}]
    }
  },

  watch:{
    query:{
      handler(val){
        this.page = 1;
        this.fetchCloudMealTemplates()
      },
      deep: true
    }
  },
  mounted(){
    // this.fetchCloudMealTemplates()
  },
  methods:{
    fetchCloudMealTemplates(){

      const query = {
        limit: this.query.perPage,
        offset: (this.page - 1) * this.query.perPage,
        type: 'meals'
      };



      if(this.query.tagIds) query.tags = this.query.tagIds;
      if(this.query.search) query.search = this.query.search;
      if(this.query.order) query.order = this.query.order;

      this.$axios.get('/cloud/listings', {params:query}).then(res => {
        this.query.totalRows = res.data.count;
        this.listings = res.data.rows;
      }).catch(e=>{
        console.log(e);
      });

    }
  }
}
</script>
